.base {
  display: flex;
  flex-direction: row;

  .title {
    max-width: 380px;
    margin: 15px 15px;
    font-weight: bold;
  }

  .content {
    max-width: 380px;
    margin: 15px 15px;
  }

  .value {
    font-weight: bold;
    margin-left: 5px;
  }
}
